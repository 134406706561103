import * as React from 'react'
import { ContentSection } from 'components/baseComponents'
import { title, alt, img2, img1, paragraphs } from './data'
import Seo from 'components/seo'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const InstallationsSection: React.FC = () => {
  React.useEffect(() => {
    const contentSections = document.querySelectorAll('.section')
    contentSections.forEach((contentSection) => {
      gsap.fromTo(
        contentSection,
        { opacity: 0 },
        {
          opacity: 1,
          stagger: 0.2,
          duration: 1,
          ease: 'easeInOut',
          scrollTrigger: {
            trigger: contentSection as any,
            start: 'top 80%',
            end: '+=300px',
            scrub: 1,
          },
        }
      )
    })
  }, [])

  return (
    <>
      <Seo image={img1} />
      <ContentSection title={title} alt={alt} fullSizeImg={img2} smallSizeImg={img1} paragraphs={paragraphs} />
    </>
  )
}

export default InstallationsSection
