import * as React from 'react'
import { useIconStyles } from '../styles'
import classnames from 'classnames'

interface ILogoProps extends JSX.IntrinsicAttributes {
  width?: string
  height?: string
  className?: string
  fill?: string
}
const Logo = ({ className, width, height }: ILogoProps) => {
  const classes = useIconStyles()
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <svg width={width ? width : '60'} height={height ? height : '60'} viewBox="0 0 60 60" version="1.1">
        <rect id="Artboard1" x="0" y="0" fill="none" />
        <clipPath id="_clip1">
          <rect id="Artboard11" x="0" y="0" width="338" height="60" />
        </clipPath>
        <g clipPath="url(#_clip1)">
          <g transform="matrix(1,0,0,1,10.2639,52.7296)">
            <path
              d="M0,-45.325C-11.719,-35.053 -13.399,-18.05 -5.059,-5.837C-4.587,-5.145 -3.6,-5.051 -3.007,-5.643L2.296,-10.947C2.722,-11.372 2.814,-12.033 2.509,-12.551C-1.798,-19.828 -1.095,-29.248 4.618,-35.825C5.124,-36.408 6.023,-36.433 6.568,-35.888L11.246,-31.21L19.613,-39.577C21.162,-41.126 23.706,-41.283 25.3,-39.782C26.95,-38.228 26.979,-35.63 25.389,-34.039L16.903,-25.554L22.56,-19.897L30.927,-28.263C32.476,-29.812 35.02,-29.969 36.613,-28.468C38.264,-26.914 38.293,-24.316 36.702,-22.725L28.217,-14.24L32.895,-9.562C33.439,-9.017 33.414,-8.117 32.832,-7.612C26.255,-1.899 16.834,-1.196 9.557,-5.502C9.039,-5.808 8.378,-5.715 7.953,-5.29L2.649,0.013C2.059,0.605 2.149,1.592 2.84,2.063C14.519,10.041 30.579,8.854 40.944,-1.512C52.937,-13.503 52.654,-33.12 40.099,-44.753C28.925,-55.105 11.454,-55.366 0,-45.325"
              fill="rgb(219,170,0)"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
export default Logo
