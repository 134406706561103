import * as React from 'react'
import './realizations.scss'
import BaseSection from 'components/baseComponents/baseSection'
import ImageSlider from './imageSlider'
import { SlideImages } from './data'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const RealizationsSection: React.FC = () => {
  React.useEffect(() => {
    const servicesSection = document.getElementsByClassName('realizations')
    gsap.fromTo(
      servicesSection,
      { opacity: 0 },
      {
        opacity: 1,
        stagger: 0.2,
        duration: 1,
        scrollTrigger: {
          trigger: servicesSection as any,
          start: 'top 80%',
          end: '+=300px',
          scrub: 1,
        },
      }
    )
  }, [])

  return (
    <BaseSection title="Nasze Realizacje" className="realizations" fullWidth={true}>
      <ImageSlider images={SlideImages} />
    </BaseSection>
  )
}

export default RealizationsSection
