import { ISlideImage } from 'model'
import realizationImage1 from 'images/realizations/image1.jpg'
import realizationImage2 from 'images/realizations/image2.jpg'
import realizationImage3 from 'images/realizations/image3.jpg'
import realizationImage4 from 'images/realizations/image4.jpg'
import realizationImage5 from 'images/realizations/image5.jpg'
import realizationImage6 from 'images/realizations/image6.jpg'
import realizationImage7 from 'images/realizations/image7.jpg'
import realizationImage8 from 'images/realizations/image8.jpg'
import realizationImage9 from 'images/realizations/image9.jpg'
import realizationImage10 from 'images/realizations/image10.jpg'
import realizationImage11 from 'images/realizations/image11.jpg'

export const SlideImages: ISlideImage[] = [
  {
    image: realizationImage1,
  },
  {
    image: realizationImage2,
  },
  {
    image: realizationImage3,
  },
  {
    image: realizationImage4,
  },
  {
    image: realizationImage5,
  },
  {
    image: realizationImage6,
  },
  {
    image: realizationImage7,
  },
  {
    image: realizationImage8,
  },
  {
    image: realizationImage9,
  },
  {
    image: realizationImage10,
  },
  {
    image: realizationImage11,
  },
]
