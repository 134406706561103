import * as React from 'react'
import BaseSection from 'components/baseComponents/baseSection'
import './contentSection.scss'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

interface IContentSectionProps {
  title: string
  paragraphs: string[]
  isReverse?: boolean
  fullSizeImg: string
  smallSizeImg: string
  alt: string
}

export const ContentSection = ({
  title,
  paragraphs,
  isReverse,
  fullSizeImg,
  smallSizeImg,
  alt,
}: IContentSectionProps) => {
  React.useEffect(() => {
    const contentSections = document.querySelectorAll('.content-section')
    contentSections.forEach((contentSection) => {
      gsap.fromTo(
        contentSection,
        { opacity: 0 },
        {
          opacity: 1,
          stagger: 0.2,
          duration: 1,
          ease: 'easeInOut',
          scrollTrigger: {
            trigger: contentSection as any,
            start: 'top 80%',
            end: '+=300px',
            scrub: 1,
          },
        }
      )
    })
  }, [])

  return (
    <BaseSection className="content-section">
      <div className={isReverse ? 'about--reverse' : 'about'}>
        <picture className={isReverse ? 'about__left-container--reverse' : 'about__left-container'}>
          <img alt={alt} sizes="(max-width: 1023px) 100vw" srcSet={`${smallSizeImg}, ${fullSizeImg}`} loading="lazy" />
        </picture>
        <div className="about__right-container">
          <h2 className="title">{title}</h2>
          {paragraphs.map((item) => (
            <p className="paragraph" key={item}>
              {item}
            </p>
          ))}
        </div>
      </div>
    </BaseSection>
  )
}
