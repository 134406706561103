import * as React from 'react'
import './services.scss'
import ServiceElement from './serviceElement'
import BaseSection from 'components/baseComponents/baseSection'
import { ServiceData } from './data'
import ServiceDescription from './serviceDescription'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const Services: React.FC = () => {
  React.useEffect(() => {
    const servicesSection = document.getElementsByClassName('services-section')
    gsap.fromTo(
      servicesSection,
      { opacity: 0 },
      {
        opacity: 1,
        stagger: 0.2,
        duration: 1,
        scrollTrigger: {
          trigger: servicesSection as any,
          start: 'top 80%',
          end: '+=300px',
          scrub: 1,
        },
      }
    )
  }, [])

  return (
    <BaseSection className="services-section">
      <div className="services">
        <div className="services__description">
          <ServiceDescription />
        </div>
        <div className="services__data">
          {ServiceData.map((item, index) => (
            <ServiceElement key={index} {...item} />
          ))}
        </div>
      </div>
    </BaseSection>
  )
}

export default Services
