import * as React from 'react'
import { useIconStyles } from '../styles'
import classnames from 'classnames'

interface ILogoBlackProps extends JSX.IntrinsicAttributes {
  width?: string
  height?: string
  className?: string
  fill?: string
}
const LogoBlack = ({ className, width, height }: ILogoBlackProps) => {
  const classes = useIconStyles()
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <svg width={width ? width : '220'} height={height ? height : '60'} viewBox="0 0 340 60" version="1.1">
        <rect id="Artboard1" x="0" y="0" fill="none" />
        <clipPath id="_clip1">
          <rect id="Artboard11" x="0" y="0" width="338" height="60" />
        </clipPath>
        <g clipPath="url(#_clip1)">
          <g transform="matrix(1,0,0,1,315.416,42.283)">
            <path
              d="M0,-24.914L-12.801,-24.914L-12.801,-32.457L22.057,-32.457L22.057,-24.914L9.257,-24.914L9.257,7.543L0,7.543L0,-24.914ZM-17.658,0.114L-17.658,7.543L-48.63,7.543L-48.63,-32.457L-18.401,-32.457L-18.401,-25.029L-39.43,-25.029L-39.43,-16.343L-20.858,-16.343L-20.858,-9.143L-39.43,-9.143L-39.43,0.114L-17.658,0.114ZM-57.117,-32.457L-57.117,7.543L-64.718,7.543L-84.66,-16.743L-84.66,7.543L-93.804,7.543L-93.804,-32.457L-86.146,-32.457L-66.26,-8.171L-66.26,-32.457L-57.117,-32.457ZM-109.148,-12.457C-109.148,-20.057 -114.52,-25.257 -121.662,-25.257C-128.806,-25.257 -134.177,-20.057 -134.177,-12.457C-134.177,-4.857 -128.806,0.343 -121.662,0.343C-114.52,0.343 -109.148,-4.857 -109.148,-12.457M-143.549,-12.457C-143.549,-24.343 -134.291,-33.143 -121.662,-33.143C-109.091,-33.143 -99.776,-24.4 -99.776,-12.457C-99.776,-0.514 -109.091,8.229 -121.662,8.229C-134.291,8.229 -143.549,-0.571 -143.549,-12.457M-157.379,-4.343C-157.379,-7.714 -159.95,-9.314 -164.75,-9.314L-175.55,-9.314L-175.55,0.571L-164.75,0.571C-159.95,0.571 -157.379,-0.914 -157.379,-4.343M-175.55,-25.486L-175.55,-16.057L-166.351,-16.057C-161.836,-16.057 -159.379,-17.657 -159.379,-20.8C-159.379,-23.943 -161.836,-25.486 -166.351,-25.486L-175.55,-25.486ZM-148.064,-3.371C-148.064,3.543 -153.55,7.543 -164.064,7.543L-184.75,7.543L-184.75,-32.457L-165.207,-32.457C-155.207,-32.457 -150.064,-28.286 -150.064,-22.057C-150.064,-18.057 -152.121,-14.972 -155.379,-13.257C-150.921,-11.829 -148.064,-8.4 -148.064,-3.371M-201.095,-12.457C-201.095,-20.058 -206.466,-25.257 -213.609,-25.257C-220.752,-25.257 -226.123,-20.058 -226.123,-12.457C-226.123,-4.857 -220.752,0.343 -213.609,0.343C-206.466,0.343 -201.095,-4.857 -201.095,-12.457M-235.495,-12.457C-235.495,-24.343 -226.237,-33.143 -213.609,-33.143C-201.037,-33.143 -191.723,-24.4 -191.723,-12.457C-191.723,-0.514 -201.037,8.229 -213.609,8.229C-226.237,8.229 -235.495,-0.571 -235.495,-12.457"
              fill="white"
            />
          </g>
          <g transform="matrix(1,0,0,1,10.2639,52.7296)">
            <path
              d="M0,-45.325C-11.719,-35.053 -13.399,-18.05 -5.059,-5.837C-4.587,-5.145 -3.6,-5.051 -3.007,-5.643L2.296,-10.947C2.722,-11.372 2.814,-12.033 2.509,-12.551C-1.798,-19.828 -1.095,-29.248 4.618,-35.825C5.124,-36.408 6.023,-36.433 6.568,-35.888L11.246,-31.21L19.613,-39.577C21.162,-41.126 23.706,-41.283 25.3,-39.782C26.95,-38.228 26.979,-35.63 25.389,-34.039L16.903,-25.554L22.56,-19.897L30.927,-28.263C32.476,-29.812 35.02,-29.969 36.613,-28.468C38.264,-26.914 38.293,-24.316 36.702,-22.725L28.217,-14.24L32.895,-9.562C33.439,-9.017 33.414,-8.117 32.832,-7.612C26.255,-1.899 16.834,-1.196 9.557,-5.502C9.039,-5.808 8.378,-5.715 7.953,-5.29L2.649,0.013C2.059,0.605 2.149,1.592 2.84,2.063C14.519,10.041 30.579,8.854 40.944,-1.512C52.937,-13.503 52.654,-33.12 40.099,-44.753C28.925,-55.105 11.454,-55.366 0,-45.325"
              fill="rgb(219,170,0)"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
export default LogoBlack
