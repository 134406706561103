import * as React from 'react'
import './contactSection.scss'
import BaseSection from 'components/baseComponents/baseSection'
import ReactMapGL, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import Logo from 'icons/logo/logo'
import { CompanyData } from './companyData'

const location = {
  latitude: 50.917268,
  longitude: 17.523904,
}

const ContactSection: React.FC = () => {
  const [viewport, setViewport] = React.useState({
    latitude: 50.917268,
    longitude: 17.523904,
    zoom: 13,
  })

  return (
    <BaseSection className="map-section" title="Kontakt" fullWidth={true}>
      <div className="map-section__wrapper">
        <CompanyData />
        <div className="map">
          <ReactMapGL
            scrollZoom={false}
            doubleClickZoom={false}
            touchAction={'pan-y'}
            {...viewport}
            width="100vw"
            height="50vh"
            mapboxApiAccessToken="pk.eyJ1Ijoia2FtaWxzIiwiYSI6ImNrZzZxdmR3ZjAwMmUycG40NWZiMm1saHcifQ.gpMYgvjbD7SJm82vZKWdrw"
            onViewportChange={(viewport) => {
              setViewport(viewport as any)
            }}
          >
            <Marker latitude={location.latitude} longitude={location.longitude}>
              <Logo height="32px" />
            </Marker>
          </ReactMapGL>
        </div>
      </div>
    </BaseSection>
  )
}

export default ContactSection
