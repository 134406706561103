import { IService } from 'model'
import { mdiCctv } from '@mdi/js'
import { mdiFlash } from '@mdi/js'
import { mdiFlashCircle } from '@mdi/js'
import { mdiAlarmLightOutline } from '@mdi/js'
import { mdiPowerSocketDe } from '@mdi/js'
import { mdiFlashAlertOutline } from '@mdi/js'
import { mdiLedStripVariant } from '@mdi/js'
import { mdiCardAccountPhone } from '@mdi/js'
import { mdiStove } from '@mdi/js'
import { mdiLightbulbOnOutline } from '@mdi/js'

export const ServiceData: IService[] = [
  { title: 'Pomiary elektryczne', iconPath: mdiFlashCircle },
  { title: 'Monitoring', iconPath: mdiCctv },
  { title: 'Systemy alarmowe', iconPath: mdiAlarmLightOutline },
  { title: 'Usuwanie awarii', iconPath: mdiFlashAlertOutline },
  { title: ' Podłączanie płyt indukcyjnych i ceramicznych', iconPath: mdiStove },
  { title: 'Montaż gniazd i osprzętu elektrycznego', iconPath: mdiPowerSocketDe },
  { title: ' Oświetlenie LED', iconPath: mdiLightbulbOnOutline },
  { title: 'Rozdzielnice elektryczne', iconPath: mdiFlash },
  { title: 'Domofony i wideodomofony', iconPath: mdiCardAccountPhone },
  { title: 'Montaż opraw oświetleniowych', iconPath: mdiLedStripVariant },
]
