import aboutImg2 from '../../images/aboutUs/AboutImg2.jpg'
import aboutImg from '../../images/aboutUs/AboutImg.jpg'

export const title = 'O Firmie'
export const paragraphs = [
  'Firma OBOnet działa nieprzerwanie od 2010 roku, świadcząc usługi elektryczne na najwyższym poziomie. Stawiamy przede wszystkim na profesjonalne podejście oraz pełne zadowolenie klienta.',
  'W tym czasie zrealizowaliśmy wiele ciekawych oraz wymagających zleceń na terenie woj. opolskiego oraz dolnośląskiego.',
  'Wyróżnia nas wysoka jakość wykonanych prac, dokładność, poważne podejście w kwestii bezpieczeństwa oraz wsparcie techniczne po zakończeniu zlecenia.',
  'OBOnet to firma sprawdzona i znana na lokalnym rynku, czego dowodem są pozytywne opinie klientów, z którymi współpracowaliśmy.',
]

export const img1 = aboutImg2
export const img2 = aboutImg
export const alt = 'wierka'
