import rImg2 from '../../images/instalations/Rozdzielna.com.jpg'
import rImg1 from '../../images/instalations/Realizacje.jpg'

export const title = 'Fachowy Elektryk'
export const paragraphs = [
  `Poszukujesz firmy elektrycznej, która sprosta Twoim oczekiwaniom? Chciałbyś zrealizować wymarzony projekt, ale obawiasz się komplikacji ze strony wykonawcy? `,
  `My Tobie pomożemy! `,
  'Wykonujemy instalacje elektryczne z pasją i z dbałością o każdy szczegół.',
  'Profesjonalne podejście na wyciągnięcie ręki i na każdą kieszeń. Skontaktuj się z nami w celu wstępnej wyceny oraz uzgodnienia warunków współpracy.',
]

export const img1 = rImg2
export const img2 = rImg1
export const alt = 'rozdzielnia'
