import * as React from 'react'
import { FaFacebook, FaGoogle } from 'react-icons/fa'

export const address = ['Brzeska 34, 49-313 Lubsza']
export const openHours = ['Pon-Pt 8:00-22:00', 'So-Ndz 8:00-16:00']
export const contact = [
  { href: 'mailto:obonet@obonet.eu', contact: 'obonet@obonet.eu' },
  { href: 'tel:+48660519193', contact: '+48 660 519 193' },
]

export const socialMedia = [
  {
    icon: <FaGoogle />,
    iconDescription: 'Sprawdź nasze opinie!',
    url:
      'https://www.google.com/maps/place/Elektryk+Brzeg+%22OBOnet+Tomasz+Obodzi%C5%84ski%22/@50.8562677,17.4660895,15z/data=!4m5!3m4!1s0x0:0x59ac2a6b1d5eed7c!8m2!3d50.8562677!4d17.4660895',
    label: 'Google',
  },
  {
    icon: <FaFacebook />,
    iconDescription: 'Odwiedź naszego Facebooka',
    url: 'https://www.facebook.com/obonetbrzeg',
    label: 'Facebook',
  },
]
