import * as React from 'react'
import Introduction from 'components/introductionSection'
import ContactSection from 'components/contactSection'
import AboutSection from 'components/aboutSection'
import RealizationsSection from 'components/realizationsSection'
import Services from 'components/servicesSection'
import InstallationsSection from 'components/installationsSection'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const MainPage: React.FC = () => {
  return (
    <>
      <Introduction />
      <InstallationsSection />
      <AboutSection />
      <Services />
      <RealizationsSection />
      <ContactSection />
    </>
  )
}

export default MainPage
